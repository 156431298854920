import $ from "jquery";
import _ from "underscore";

class FacetMultiSelect {
  constructor(options, select, form) {
    this.$multiSelect = $(select);
    this.$select = $("<select></select>");
    this.$multiSelect.parent().append(this.$select);
    this.$form = $(form);
    this.$form.on("removeOption", (e, option) => {
      if (option.name === this.$multiSelect.attr("name")) {
        this.render();
      }
    });
    this.$form.on("removeOptions", () => {
      this.selectedOptions.forEach(option => {
        option.selected = false;
      });
      this.render();
    });
    this.attacheEvents(this.$select);

    this.options = options;
    this.render();
    this.$form.find("button").show();
  }

  get selectedOptions() {
    return this.options.filter(option => option.selected);
  }

  get unselectedOptions() {
    return this.options.filter(option => !option.selected);
  }

  get template() {
    return _.template(`
        <% _.each(options, function(option) { %>
          <option value="<%= option.value %>"><%= option.text %></option>
        <% }); %>`);
  }

  render() {
    const options = $(this.template({ options: this.unselectedOptions }));
    this.$select.empty().append(options);
    this.updateValue();
  }

  updateValue() {
    const value = this.selectedOptions.map(option => option.value);
    this.$multiSelect.val(value);
  }

  addOption(option) {
    option.selected = true;
    this.$form.trigger("addOption", option);
    this.render();
  }

  attacheEvents(select) {
    const that = this;
    $(select).on("change", e => {
      const $select = $(e.currentTarget);
      const value = $select.val();
      if (value.length) {
        const option = that.options.find(o => value === o.value);
        this.addOption(option);
        this.$form.submit();
      }
    });
  }
}

class FacetsValues {
  get template() {
    return _.template(`
      <li data-name="<%= option.name %>" data-value="<%= option.value %>">
        <span class="primary label reset">
          <a href="#">
              <svg width="10px" height="10px" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"/></svg>
              <%= option.text %>
          </a>
        </span>
      </li>`);
  }
  get resetTemaplate() {
    return _.template(`
      <li id="reset-all" data-name="all" data-value="all">
        <span class="primary label reset">
          <a href="#">
          <svg width="10px" height="10px" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"/></svg> Reset
          </a>
        </span>
      </li>`);
  }

  constructor(options, container, form) {
    this.options = options;
    this.$container = $(container);
    this.$container.empty();
    this.$form = $(form);
    this.$form.on("addOption", () => {
      this.render();
    });
    this.render();
  }

  get selectedOptions() {
    return this.options.filter(option => option.selected);
  }

  removeOption(option) {
    option.selected = false;
    this.$form.trigger("removeOption", option);
    if (this.selectedOptions.length === 0) {
      $("#reset-all").remove();
    }
  }

  removeOptions() {
    this.$form.trigger("removeOptions");
  }

  render() {
    this.$container.empty();
    this.selectedOptions.forEach(selectedOption => {
      const $label = $(this.template({ option: selectedOption }));
      const $resetOption = $(this.resetTemaplate());
      this.attacheEvents($label);
      this.$container.append($label);
      if (this.selectedOptions.length > 0 && $("#reset-all").length === 0) {
        this.$container.prepend($resetOption);
      }
    });
    this.resetAll();
    return this.$container.get();
  }

  attacheEvents(label) {
    const $label = $(label);
    const that = this;
    $label.find("a").on("click", e => {
      const $optionLabel = $(e.currentTarget).parents("li");
      const name = $label.data("name");
      const value = $label.data("value").toString();
      const optionEl = that.options.find(o => value === o.value && name === o.name);
      $optionLabel.remove();
      this.removeOption(optionEl);
      this.$form.submit();
      e.preventDefault();
    });
  }

  resetAll() {
    $("#reset-all")
      .find("a")
      .on("click", e => {
        this.removeOptions();
        $("#reset-all").remove();
        this.render();
        this.$form.submit();
        e.preventDefault();
      });
  }
}

$(document).ready(() => {
  const $facetsForm = $("[data-facets-form]");
  const $facetsValues = $("[data-facets-seleced]");
  if ($facetsForm.length && $facetsValues.length) {
    const selects = [];
    const allOptions = $facetsForm
      .find("select[multiple] option")
      .map((index, option) => {
        const $option = $(option);
        const optionName = $option.parent().attr("name");
        return {
          text: $option.text(),
          value: $option.val(),
          name: optionName,
          selected: $option.is(":selected"),
        };
      })
      .get();

    new FacetsValues(allOptions, $facetsValues, $facetsForm);

    $facetsForm.find("select[multiple]").each((index, select) => {
      const name = $(select).attr("name");
      const options = allOptions.filter(option => option.name === name);
      selects.push(new FacetMultiSelect(options, select, $facetsForm));
    });
  }
});
