import $ from "jquery";

var slickOptions = {
  arrows: false,
  slidesToShow: 5,
  infinite: false,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnDotsHover: true,
  touchThreshold: 7,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 641,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function sliderConfig() {
  if ($(".products-slider").length > 0) {
    $(".products-slider").not(".slick-initialized").slick(slickOptions);
  }
}

$(window).on("resize orientationchange", function () {
  $(".products-slider").slick("resize");
});

export default sliderConfig;
