import $ from "jquery";

export function i18lAddressForm() {
  const $countrySelect = $('[autocomplete$="country"]');
  if ($countrySelect.length) {
    const $stateInput = $countrySelect.parents("form").find('[autocomplete$="address-level1"]');
    const $stateField = $stateInput.parents(".country_area");
    const validationUrl = $countrySelect.data("validation-url");
    const inputData = {
      name: $stateInput.attr("name"),
      autocomplete: $stateInput.attr("autocomplete"),
      value: $stateInput.val(),
    };
    const $stateSelect = $(`<select name="${inputData.name}" autocomplete="${inputData.autocomplete}"></select>`);

    $countrySelect.change(e => {
      const currentCountrySelect = $(e.currentTarget)
      const countryCode = currentCountrySelect.find("option").filter(":selected").val();
      const countryValidationUrl = validationUrl.replace("$$", countryCode);
      const localStateField = currentCountrySelect.parents(".address-form").find("[id$='country_area']")
      $.getJSON(countryValidationUrl).done(data => {
        if (data.country_area_choices && data.country_area_choices.length) {
          localStateField.parent().removeClass("hide");
        } else if (data.require.indexOf("country_area") !== -1) {
          localStateField.parent().removeClass("hide");
        } else {
          localStateField.parent().addClass("hide");
        }
      });
    });
  }
  $countrySelect.change();
}

export default i18lAddressForm;
