import $ from "jquery";

$(document).ready(() => {
  const form = $("#change-default-address-form");
  const buttons = form.find("input[type='radio']");
  buttons.each((index, element) => {
    $(element).on("click", () => {
      form.submit();
    });
  });
});
