import $ from "jquery";
import { addTackingCheck } from "./cookies.js";

function trackBasket(email, product_list) {
  dataLayer.push({
    PageType: "BasketPage",
    email: email,
    ProductBasketProducts: product_list,
  });
}

function trackSale(email, product_list, transaction_id) {
  dataLayer.push({
    PageType: "TransactionPage",
    email: email,
    ProductTransactionProducts: product_list,
    TransactionID: transaction_id,
  });
}

const pushCriteoDataToGTM = email => {

  const trackBasketContainer = $("#criteo-track-basket");
  if (trackBasketContainer.length > 0) {
    const basketData = trackBasketContainer.data("basket-data");
    trackBasket(email, basketData);
  }

  const trackSaleContainer = $("#criteo-track-sale");
  if (trackSaleContainer.length > 0) {
    const productData = trackSaleContainer.data("product");
    const transactionData = trackSaleContainer.data("transaction");
    trackSale(email, productData, transactionData);
  }
};

$(document).on("sessionData", (e, data) => {
  $(document).ready(() => {
    if (addTackingCheck(data)) {
      pushCriteoDataToGTM(data.hashed_email);
    } else {
      // Push anonymous data if user did not enabled the tracking
      pushCriteoDataToGTM("");
    }
  });
});
