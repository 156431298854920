import $ from "jquery";

$(document).ready(() => {
  $.urlParam = name => {
    const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);
    if (results == null) {
      return null;
    }
    return decodeURI(results[1]) || 0;
  };
  const campaignId = $.urlParam("mc_cid");
  if (campaignId) {
    const expireTime = 5 * 24 * 60;
    document.cookie = `mailchimp_campaign_id=${campaignId}; expires=${expireTime}; path=/`;
  }
});
