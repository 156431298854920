import * as Sentry from "@sentry/browser";
import * as traverse from "traverse";

const PRODUCTION_SENTRY_DSN = process.env.PRODUCTION_SENTRY_DSN;
const STAGING_SENTRY_DSN = process.env.STAGING_SENTRY_DSN;
const QA_SENTRY_DSN = process.env.STAGING_SENTRY_DSN;
const SENTRY_ENVIRONMENT = "frontend";
const SENTRY_SENSITIVE_KEYWOARDS = ["password", "token", "secret", "cccard", "adyen"];

let SENTRY_DSN;

if (window.location.hostname == "www.prettygreen.com") {
  SENTRY_DSN = PRODUCTION_SENTRY_DSN;
}
if (window.location.hostname == "www.stage.prettygreen.com") {
  SENTRY_DSN = STAGING_SENTRY_DSN;
}
if (window.location.hostname == "www.qa.prettygreen.com") {
  SENTRY_DSN = QA_SENTRY_DSN;
}

const SENTRY_ON = !!SENTRY_DSN;

if (SENTRY_ON) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    beforeSend: event => {
      return traverse.map(event, function () {
        const key = this.path.join(" ");
        const isSensitive = SENTRY_SENSITIVE_KEYWOARDS.some(keyword => key.toLowerCase().includes(keyword));
        if (isSensitive && this.isLeaf) {
          this.update("ANONIMIZED");
        }
      });
    },
  });
}

export default Sentry;
