import $ from "jquery";
import _ from "underscore";

class Options {
  constructor(options, select, title) {
    this.$el = $("<ul></ul>");
    this._options = options;
    this.$select = $(select);
    this.title = title;
  }

  get template() {
    return _.template(`
      <h5><%= title %> - </h5>
      <% _.each(options, function(option) { %>
          <li class="<%= option.type %> <% if (option.selected) { %>selected<% } %> <% if (option.disabled) { %>disabled<% } %>" data-value="<%= option.raw_value %>">
            <a class="option" href="#"><%= option.name %></a>
          </li>
        <%}); %>
      `);
  }

  get options() {
    return this._options;
  }

  get selected() {
    return this.options.find(option => option.selected);
  }

  get context() {
    return {
      selectedOption: this.selected,
      options: this.options,
      title: this.title,
    };
  }

  attacheEvents() {
    this.$el.find("a.option").on("click", e => {
      const $option = $(e.currentTarget).parents("li");
      const value = $option.data("value");
      const option = this.options.find(o => value.toString() === o.raw_value);
      this.selectOption(option);
      e.preventDefault();
    });
  }

  selectOption(option) {
    this.options.forEach(optionEl => {
      optionEl.selected = false;
    });
    option.selected = true;
    this.$select.trigger("selectOption", option);
  }

  render() {
    const options = $(this.template(this.context));
    this.$el.empty().append(options);
    this.attacheEvents();
    return this.$el;
  }
}

class SizeOptions extends Options {
  constructor(options, select, title, sizeGuide) {
    super(options, select, title);
    this.sizeGuide = sizeGuide;
  }

  get options() {
    return this._options.filter(option => option.type === "size");
  }

  get context() {
    return {
      selectedOption: this.selected,
      options: this.options,
      title: this.title,
      sizeGuide: this.sizeGuide,
    };
  }

  get template() {
    return _.template(`
      <p><%= title %><span class="size-guide"> - </span><a class="size-guide modal" data-open="<%= sizeGuide.id %>"><%= sizeGuide.trans %></a></p>
      <% _.each(options, function(option) { %>
          <li class="<%= option.type %> <% if (option.selected) { %>selected<% } %> <% if (option.disabled) { %>disabled<% } %>" data-value="<%= option.raw_value %>">
            <a class="option" href="#"><%= option.name %></a>
          </li>
        <%}); %>
      `);
  }
}

class ColorOptions extends Options {
  get options() {
    return this._options.filter(option => option.type === "color");
  }

  get template() {
    return _.template(`
      <p><%= title %> - <strong id="selectedColor"><%= selectedOption.name %></strong></p>
      <% _.each(options, function(option) { %>
          <li class="<%= option.type %> <% if (option.selected) { %>selected<% } %> <% if (option.disabled) { %>disabled<% } %>" data-value="<%= option.raw_value %>">
            <a class="option image" title="<%= option.name %>" href="#">
              <img src="<%= option.image %>" srcset="<%= option.image %> 1x, <%= option.image2x %> 2x" alt="<%= option.name %>" />
            </a>
            </li>
        <%}); %>
      `);
  }
}

class VariantPicker {
  constructor(
    options,
    select,
    productCodeField,
    productPriceField,
    currentColor,
    currentSize,
    productQuantity,
    currency,
  ) {
    this.$el = $('<div id="variants-picker"></div>');
    this._currentColor = currentColor;
    this._currentSize = currentSize;
    this.options = options;
    this.$select = $(select);
    this.$productCodeField = productCodeField;
    this.$productPriceField = productPriceField;
    this.$productQuantity = productQuantity;
    this.sku = this.$select.data("sku");
    this.variants = this.$select.data("variants");
    this.stockAlert = 3;
    this.currency = currency;

    this.$select.on("selectOption", (e, option) => this.selectOption(option));

    this.sizes = new SizeOptions(options, select, this.$select.data("trans-chose-size"), {
      trans: this.$select.data("trans-size-guide"),
      id: this.$select.data("id-size-guide"),
    });
    this.colors = new ColorOptions(options, select, this.$select.data("trans-chose-color"));

    // Set current state
    const selectValue = +this.$select.val();
    const variant = this.variants.find(variantEl => variantEl.id === selectValue);
    if (variant) {
      this.options.forEach(option => {
        option.selected = false;
        option.disabled = false;
        if (option.type === "size" && option.raw_value === variant.size) {
          option.selected = true;
        }

        if (option.type === "color" && option.raw_value === variant.color) {
          option.selected = true;
        }
      });
    } else if (currentColor || currentSize) {
      this.options.forEach(option => {
        option.selected = false;
        option.disabled = false;
        if (option.type === "color" && option.raw_value === currentColor) {
          option.selected = true;
        }
        if (option.type === "size" && option.raw_value === currentSize) {
          option.selected = true;
        }
      });
    } else {
      this.options.forEach(option => {
        option.selected = false;
        option.disabled = false;
      });
    }

    this.$select.hide();

    // Render
    const selected = this.options.filter(option => option.selected);
    if (selected.length === 1) {
      this.selectOption(selected[0]);
    } else if (selected.length === 2) {
      if (selected[0].type === "color") {
        this.selectOption(selected[0]);
      } else {
        this.selectOption(selected[1]);
      }
    } else {
      this.render();
    }
  }

  get currentColor() {
    const option = this.options.find(optionEl => optionEl.selected && optionEl.type === "color");
    return option ? option.raw_value : this._currentColor;
  }

  get currentSize() {
    const option = this.options.find(optionEl => optionEl.selected && optionEl.type === "size");
    return option ? option.raw_value : this._currentSize;
  }

  get selectedVariant() {
    const selected = this.options.filter(option => option.selected);
    let color;
    let size;
    let variant;
    if (selected.length === 2) {
      if (selected[0].type === "size") {
        size = selected[0].raw_value;
        color = selected[1].raw_value;
      } else {
        size = selected[1].raw_value;
        color = selected[0].raw_value;
      }
      variant = this.variants.find(variantEl => variantEl.color === color && variantEl.size === size);
    } else {
      variant = null;
    }
    return variant || null;
  }

  selectOption(option) {
    const variant = this.selectedVariant;
    if (option.type === "color") {
      const availableVariants = this.variants.filter(variantEl => variantEl.color === option.raw_value);
      const optionNames = availableVariants.map(variantEl => variantEl.size);
      this.disableAllOptionsExcept("size", optionNames);
      this.$productCodeField.text(`${this.sku}-${option.raw_value.toUpperCase()}`);
    }

    if (variant) {
      this.$productPriceField.html(variant.price);
      const $price = this.$productPriceField.find(".price");
      const sessionCurrency = this.currency;
      $price.each((index, element) => {
        if ($(element).data("currency") === sessionCurrency) {
          $(element).removeClass("hide");
          $(element).text($(element).data("price"));
          $(".tax-info").removeClass("hide");
        }
      });
      this.$select.val(variant.id);
      if (variant.quantity < this.stockAlert) {
        $(".stock-quantity").removeClass("hide");
        this.$productQuantity.text(variant.quantity);
      } else {
        $(".stock-quantity").addClass("hide");
      }
    } else if (this.$select.data("product-status") !== "coming_soon") {
      const outOfStock = this.$productPriceField.data("price");
      this.$productPriceField.html(`<span class="label lead">${outOfStock}</span>`);
      $(".tax-info").addClass("hide");
      this.$select.val("");
    }
    this.$select.select();
    this.render();
    this.updateHistory();
  }

  updateHistory() {
    let state = history.state;
    if (!state) {
      state = { color: this._currentColor, size: this._currentSize };
    }
    if (state.color !== this.currentColor || state.size !== this.currentSize) {
      state = {
        color: this.currentColor,
        size: this.currentSize,
        reloadPage: true,
      };
      const url = `?color=${this.currentColor}&size=${this.currentSize}`;
      history.replaceState(state, "", url);
    }
  }

  disableAllOptionsExcept(type, names) {
    this.options.forEach(option => {
      option.disabled = option.type === type && names.indexOf(option.raw_value) === -1;
    });
  }

  enableAllOptions() {
    this.options.forEach(option => {
      option.disabled = false;
    });
  }

  render() {
    this.$el.empty();
    this.$el.append(this.colors.render());
    this.$el.append(this.sizes.render());
    return this.$el;
  }
}

class AvailabilityForm {
  constructor(availabilityForm, variantForm) {
    this.$availabilityForm = $(availabilityForm);
    this.$variantForm = $(variantForm);
    const $variantSelect = this.$variantForm.find("select");
    const productStatus = $variantSelect.data("product-status");

    $variantSelect.on("selectOption", (e, option) => {
      const $input = this.$availabilityForm.find(`#id_${option.type}`);
      $input.val(option.id);
    });

    $variantSelect.on("select", e => {
      const $select = $(e.currentTarget);
      const value = $select.val();
      if (value && value.length && productStatus !== "coming_soon") {
        this.$variantForm.removeClass("hide");
        this.$availabilityForm.addClass("hide");
      } else {
        this.$variantForm.addClass("hide");
        this.$availabilityForm.removeClass("hide");
        $(".stock-quantity").addClass("hide");
      }
    });
  }
}

$(document).on("sessionData", (e, sessionData) => {
  $(document).ready(() => {
    const $availabilityForm = $("[data-availability-form]");
    const $variantForm = $("[data-variants-form]");
    const $variantSelect = $variantForm.find("select");

    const $productCodeField = $(".product-detail__content #product-code");
    const $productPriceField = $("[data-price]");
    const $productQuantity = $(".stock-quantity span");

    if ($variantSelect.length) {
      const currentColor = $variantSelect.data("current-color");
      const currentSize = $variantSelect.data("current-size").toString();
      const options = $variantSelect.data("options");

      if (options.length) {
        const variantPicker = new VariantPicker(
          options,
          $variantSelect,
          $productCodeField,
          $productPriceField,
          currentColor,
          currentSize,
          $productQuantity,
          sessionData.currency,
        );
        $variantSelect.select();
        $variantSelect.parents(".product-detail__content").prepend(variantPicker.$el);
      }

      new AvailabilityForm($availabilityForm, $variantForm);

      window.onpopstate = event => {
        if (event.state && event.state.reloadPage) {
          location.reload();
        }
      };
    }
  });
});
