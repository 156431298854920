// Modal module
// HTML structure for modal

/* <div class="reveal-overlay">
  <div id="modalId" data-reveal class="reveal">
    <button class="close-button" data-close aria-label="Close modal" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
    <div>
      Content
    </div>
  </div>
</div>

<button class="button expanded modal" data-open="modalId">{% trans "Open modal" %}</button> */

const modalContainer = $(".reveal-overlay");
const modalContent = modalContainer.find("[data-reveal]");

$("body").on("click", ".modal[data-open]", e => {
  const modalContainerId = $(e.currentTarget).data("open");
  const modalContent = $(`#${modalContainerId}`);
  const modalContainer = modalContent.parent();
  modalContainer.css("display", "block");
  modalContent.addClass("open");
});
modalContent.find(".close-button").on("click", () => {
  modalContainer.css("display", "none");
  modalContent.removeClass("open");
});
modalContent.on("click", e => {
  e.stopPropagation();
});
modalContainer.on("click", () => {
  modalContainer.css("display", "none");
  modalContent.removeClass("open");
});
