import "expose-loader?$!jquery";
import "expose-loader?jQuery!jquery";
import "babel-polyfill";
import "es6-promise/auto";
import "isomorphic-fetch";
import shim from "promise.prototype.finally/shim";
import _ from "underscore";
import { Foundation } from "foundation-sites/js/foundation.core";
import { Accordion } from "foundation-sites/js/foundation.accordion";
import { Box } from "foundation-sites/js/foundation.util.box";
import { DropdownMenu } from "foundation-sites/js/foundation.dropdownMenu";
import { Keyboard } from "foundation-sites/js/foundation.util.keyboard";
import { Nest } from "foundation-sites/js/foundation.util.nest";
import { Tabs } from "foundation-sites/js/foundation.tabs";
import { MediaQuery } from "foundation-sites/js/foundation.util.mediaQuery";
import { Motion } from "foundation-sites/js/foundation.util.motion";
import { Triggers } from "foundation-sites/js/foundation.util.triggers";
import { Drilldown } from "foundation-sites/js/foundation.drilldown";
import { OffCanvas } from "foundation-sites/js/foundation.offcanvas";
import InfiniteScroll from "infinite-scroll";

import SVGInjector from "svg-injector-2";
import "lazysizes/plugins/bgset/ls.bgset.js";
import "lazysizes/lazysizes";

import "slick-carousel/slick/slick";
import "../../scss/storefront.scss";

import "./sentry";
import i18lAddressForm from "./components/i18laddressform.js";
import sliderConfig from "./components/sliderconfig.js";
import "./components/sessiondata.js";
import "./components/variantpicker.js";
import "./components/productsimages.js";
import "./components/searchfacets.js";
import "./components/cookies-affiliate.js";
import "./components/facebookpixel.js";
import "./components/userprofile.js";
import "./components/gtm.js";
import "./components/awin_gtm.js";
import "./components/criteo-gtm.js";
import "./components/cookies.js";
import "./components/mailchimp.js";
import "./components/modal.js";

shim();

Foundation.addToJquery($);

Foundation.plugin(Accordion, "Accordion");
Foundation.plugin(Box, "Box");
Foundation.plugin(Keyboard, "Keyboard");
Foundation.plugin(MediaQuery, "MediaQuery");
Foundation.plugin(Motion, "Motion");
Foundation.plugin(Nest, "Nest");
Foundation.plugin(Triggers, "Triggers");
Foundation.plugin(DropdownMenu, "DropdownMenu");
Foundation.plugin(Tabs, "Tabs");
Foundation.plugin(Drilldown, "Drilldown");
Foundation.plugin(OffCanvas, "OffCanvas");

new SVGInjector().inject(document.querySelectorAll("svg[data-src]"));

$(document).on("sessionData", () => {
  $(document).ready(() => {
    sliderConfig();
    $(document).foundation();
    i18lAddressForm();
  });
});

$("nav ul.main-menu .parent ul").addClass("hide");

$(".cart__table__remove").on("click", e => {
  e.preventDefault();
  $($(e.target).data("target-input")).val(0);
  $("#cart_form").submit();
});

$(".cart__products__name")
  .find("input")
  .on("change", e => {
    e.preventDefault();
    $("#cart_form").submit();
  });

const $selectCountryForm = $("[data-select-country-form]");
const $countrySelect = $selectCountryForm.find("select");
$countrySelect.change(() => {
  $selectCountryForm.submit();
});

$("#country-dropdown")
  .find("button")
  .on("click", e => {
    $(e.target).parents("ul").removeClass("is-open");
  });

new InfiniteScroll(".infinite-scroll", {
  path: "ul.pagination li a.next",
  append: ".blog",
  history: false,
});

$("[data-video-link]").on("click", e => {
  const videoLink = $(e.currentTarget).data("video-link");
  const videoWidth = $(e.currentTarget).data("video-width");
  const videoHeight = $(e.currentTarget).data("video-height");
  const videoHtml = _.template(`
    <div class="responsive-embed widescreen">
       <iframe src="<%= link %>" width="<%= width %>" height="<%= height %>" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>`);
  $(e.currentTarget).html(videoHtml({ link: videoLink, width: videoWidth, height: videoHeight }));
});

$(".back-btn").on("click", () => {
  window.history.back();
});

/* Prevent loading video on mobile */

const $videoHome = $(".responsive-embed");
const videoURL = $videoHome.find("source").data("src");
if ($videoHome.length > 0) {
  if ($videoHome.hasClass("hide-for-small-only")) {
    if ($(window).width() > 640) {
      $videoHome.find("source").attr("src", videoURL);
      $videoHome.find("video")[0].load();
    }
  } else {
    $videoHome.find("source").attr("src", videoURL);
    $videoHome.find("video")[0].load();
  }
}

/*  */
$("html").addClass("js").removeClass("no-js");

$("#reveal-modal")
  .find(".close-button")
  .on("click", () => {
    $(".reveal-overlay").hide();
  });

$(".slick-test").slick({
  infinite: true,
  autoplay: false,
  slidesToShow: 6,
  slidesToScroll: 1,
  swipeToSlide: true,
  lazyLoad: "ondemand",
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
});

const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const $slide = $(".slick-slide").find(".category-product");
if (width > 450) {
  const $slideHeight = parseInt($slide.css("height"), 10) + 25;
  $slide.css("height", $slideHeight + "px");
} else {
  const $slideHeight = parseInt($slide.css("height"), 10) + 15;
  $slide.css("height", $slideHeight + "px");
}

const $blackFridayBar = $("#black-friday-bar");
if ($blackFridayBar.length > 0) {
  const firstStageDate = new Date("Dec 2, 2019 23:59:59").getTime();
  const firstStageTitle = "BLACK FRIDAY ENDS IN";

  const setCountDown = (countDownDate, title, stage) => {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    if (distance > 0) {
      $blackFridayBar.find(".title").html(title);
      // eslint-disable-next-line no-unused-vars
      const x = setInterval(function () {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        $blackFridayBar.find(".days_value").html(days);
        $blackFridayBar.find(".hours_value").html(hours);
        $blackFridayBar.find(".minutes_value").html(minutes);
        $blackFridayBar.find(".seconds_value").html(seconds);
        if (days < 0 && hours < 0 && minutes < 0 && seconds < 0 && stage === 3) {
          $blackFridayBar.hide();
        }
      }, 1000);
    }
  };
  setCountDown(firstStageDate, firstStageTitle, 1);
}
