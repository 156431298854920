import $ from "jquery";
import _ from "underscore";
import { addTackingCheck } from "./cookies.js";

/* eslint-disable */
const initFbg = trackingId => {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
  fbq("init", trackingId);
  fbq("track", "PageView");
};
/* eslint-enable */

const getCurrentVariant = () => {
  let variantColor;
  let variantSize;
  let variantSku;
  const $variantPicker = $("#variants-picker");
  const $variantSelect = $("#id_variant");
  const variants = $variantSelect.data("variants");
  const colorItem = $variantPicker.find(".color.selected");
  const sizeItem = $variantPicker.find(".size.selected");
  if (colorItem.length && sizeItem.length) {
    variantColor = $(colorItem).data("value");
    variantSize = String($(sizeItem).data("value"));
    const variant = variants.find(variantElement => {
      const variantFoundColor = variantElement.color === variantColor;
      const variantFoundSize = variantElement.size === variantSize;
      return variantFoundColor && variantFoundSize;
    });
    if (variant) {
      variantSku = variant.product_sku_with_color_and_size;
    } else {
      variantSku = "";
    }
  }
  return { color: variantColor, size: variantSize, sku: variantSku };
};

const productAdapter = (product, sku) => ({
  value: product.price,
  content_name: `${product.name}, ${product.variant}`,
  content_type: "product",
  content_category: product.category,
  content_ids: [sku],
  currency: product.currency,
});

const trackAddToCartEvent = product => {
  const currentVariant = getCurrentVariant();
  const currentProduct = product;
  if (currentVariant.color && currentVariant.size) {
    currentProduct.variant = `${currentVariant.color}, ${currentVariant.size}`;
    currentProduct.id = currentVariant.sku;
  }
  /* eslint-disable */
  fbq("track", "AddToCart", productAdapter(currentProduct, currentProduct.id));
  /* eslint-enable */
};

const productView = product => {
  const currentVariant = getCurrentVariant();
  const currentProduct = product;
  if (currentVariant.color && currentVariant.size) {
    currentProduct.variant = `${currentVariant.color}, ${currentVariant.size}`;
    currentProduct.id = currentVariant.sku;
  }
  /* eslint-disable */
  fbq("track", "ViewContent", productAdapter(currentProduct, currentProduct.id));
  /* eslint-enable */
};

const trackSearch = () => {
  const container = $("[data-tracking-search]");
  const searches = container.data("tracking-search");
  const query = container.find("meta[itemprop=name]").attr("content");
  const ids = [];

  if (searches) {
    _.each(searches, obj => {
      ids.push(obj.id);
    });
    const data = {
      content_ids: ids,
      search_string: query,
      content_type: "product",
    };
    /* eslint-disable */
    fbq("track", "Search", data);
    /* eslint-enable */
  }
};

const trackCheckout = products => {
  let sum = 0;
  const ids = [];
  _.each(products, obj => {
    sum += parseFloat(obj.price);
    ids.push(obj.id);
  });
  /* eslint-disable */
  fbq("track", "InitiateCheckout", {
    value: sum,
    content_ids: ids,
    currency: products[0].currency,
    content_type: "product",
  });
  /* eslint-enable */
};

const trackPurchase = () => {
  const container = $("[data-tracking-transaction]");
  const transaction = container.data("tracking-transaction");
  const ids = [];
  let numItems = 0;
  if (transaction) {
    _.each(transaction.products, obj => {
      ids.push(obj.id);
      numItems += obj.quantity;
    });
    /* eslint-disable */
    fbq("track", "Purchase", {
      value: transaction.revenue,
      currency: transaction.currencyCode,
      num_items: numItems,
      content_ids: ids,
      content_type: "product",
    });
    /* eslint-enable */
  }
};

$(document).on("sessionData", (e, data) => {
  $(document).ready(() => {
    const trackingId = $("html").data("facebook-tracking-id");
    if (trackingId && addTackingCheck(data)) {
      initFbg(trackingId);
      trackSearch();
      trackPurchase();

      const dataTrackingProduct = $("div.product-detail").data("tracking-product");
      if (dataTrackingProduct) {
        productView(dataTrackingProduct);

        $("[data-variants-form]").on("selectOption", () => {
          productView(dataTrackingProduct);
        });
      }

      $("[data-tracking-add-to-cart]").on("submit", e => {
        trackAddToCartEvent($(e.currentTarget).data("tracking-add-to-cart"));
      });

      $("[data-tracking-checkout]").on("click", e => {
        trackCheckout($(e.currentTarget).data("tracking-checkout"));
      });
    }
  });
});
