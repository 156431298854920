import $ from "jquery";

const pushAwinDataToGTM = () => {
  const transactionContainer = $("#awin-track-sale");
  if (transactionContainer.length > 0) {
    const transactionData = transactionContainer.data("transaction");
    dataLayer.push(transactionData);
  }
};

$(document).on("sessionData", () => {
  $(document).ready(() => {
    pushAwinDataToGTM();
  });
});
