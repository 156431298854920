// Extracts URL parameter used by Rakuten Marketing in their affiliated URLs
// adds appropriate cookie to the user's browser
// so we can retrieve it during the checkout and identify user as
// referred by Rakuten.
import $ from "jquery";
import { createCookie } from "./cookies.js";

const getAwinCookieValue = referrerName => {
  if (referrerName === "awin" || referrerName === "aw") {
    return "aw";
  } else if (referrerName) {
    return "other";
  }
};

const month = 30;

const setAwinCookie = referrerName => {
  const awinCookieValue = getAwinCookieValue(referrerName);
  const awinCookieName = "Source";
  createCookie(awinCookieName, awinCookieValue, month);
};

$(document).ready(() => {
  $.urlParam = name => {
    const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);
    if (results == null) {
      return null;
    }
    return decodeURI(results[1]) || 0;
  };
  const utmSource = $.urlParam("utm_source");
  const googleAdsSource = $.urlParam("gclid");
  const affiliatedName = utmSource || googleAdsSource;
  if (affiliatedName) {
    createCookie("referrer", affiliatedName, month);
    setAwinCookie(affiliatedName);
  }
});
