import $ from "jquery";

const cacheEnabled = document.cookie.indexOf("nocache=true") === -1;
// we need to get session data
export const sessionPromise = $.ajax("/session-data/");

function updatePrice(data) {
  const $price = $("span.price");
  $price.each((index, element) => {
    if ($(element).data("currency") === data.currency) {
      const price = $(element).data("price");
      $(element).text(price);
      $(element).removeClass("hide");
    }
  });
}

// if data fetching fail we setup default data
sessionPromise.fail(() => {
  $(document).trigger("sessionData", {
    cart_counter: 0,
    messages: [],
    is_authenticated: false,
    is_staff: null,
    currency: $("html").data("default-currency"),
    currency_display: $("html").data("default-currency-display"),
    country_flag: $("html").data("default-country-flag"),
    country: $("html").data("default-country"),
    user_id: null,
    user_email: null,
    csrf_token: null,
    enable_performance_tracking: null,
    enable_ad_tracking: null,
    tracking_acceptance_date: null,
  });
});

if (cacheEnabled) {
  sessionPromise.done((response) => {
    $("html").data("user-id", response["user-id"]);
    $("html").data("user-email", response["user-email"]);
    $(document).trigger("sessionData", response);
  });
} else {
  // If cache is not enabled data are in template,
  // trigger user data for tracking code from DOM
  // we pass only information needed in footer

  sessionPromise.done((response) => {
    let performanceTracking = $("html").data("user-performance-tracking");
    if (performanceTracking) {
      performanceTracking = performanceTracking.toLowerCase();
      performanceTracking = performanceTracking === "true";
    }
    let adTracking = $("html").data("user-ad-tracking");
    if (adTracking) {
      adTracking = adTracking.toLowerCase();
      adTracking = adTracking === "true";
    }

    $(document).trigger("sessionData", {
      client_id: response.client_id,
      cart_counter: null,
      messages: null,
      is_authenticated: response.is_authenticated,
      is_staff: response.is_staff,
      currency: $("html").data("currency"),
      currency_display: $("html").data("currency-display"),
      country_flag: $("html").data("country-flag"),
      country: $("html").data("country"),
      user_id: $("html").data("user-id"),
      user_email: $("html").data("user-email"),
      hashed_email: response.hashed_email,
      csrf_token: null,
      enable_performance_tracking: performanceTracking,
      enable_ad_tracking: adTracking,
      tracking_acceptance_date: $("html").data("tracking-acceptance-date"),
    });
  });
}

const loadButtons = () => {
  sessionPromise.then(() => {
    $("form").find('.loading[type="submit"]').prop("disabled", false);
  });
};

$(document).on("sessionData", (e, data) => {
  $(document).ready(() => {
    updatePrice(data);

    const authLink = $('[data-auth="true"]');
    const adminLink = $('[data-auth="admin"]');
    const noAuthLink = $('[data-auth="false"]');
    if (data.is_authenticated && !data.is_staff) {
      authLink.removeClass("hide");
    } else if (data.is_staff) {
      authLink.add(adminLink).removeClass("hide");
    } else {
      noAuthLink.removeClass("hide");
    }

    if (data.csrf_token) {
      const csrfToken = $('input[name="csrfmiddlewaretoken"]');
      csrfToken.attr("value", data.csrf_token);
    }
    loadButtons();
  });
});

export default updatePrice;
