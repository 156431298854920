import $ from "jquery";
import _ from "underscore";
import { Foundation } from "foundation-sites/js/foundation.core";

class FullScreanGallery {
  constructor(productImages) {
    this.$productImages = $(productImages);
    this.isFullScrean = false;
    this.currentUrl = null;

    this.$productImages.find("a").on("click.productGallery", e => {
      this.urls = [];

      const currentColor = this.$productImages.find(".product-images__link--first").data("color").toLowerCase();
      this.$productImages.find(".product-images__link img").each((index, image) => {
        let elementColor = $(image).parent().data("color");
        if (elementColor !== null) {
          elementColor = elementColor.toLowerCase();
        }
        if (elementColor === currentColor || elementColor == null) {
          this.urls.push({ src: $(image).attr("src"), srcset: $(image).attr("srcset") });
        }
      });

      const $img = $(e.currentTarget).find("img");
      if (!this.isFullScrean) {
        this.createControls();
      }
      this.setImage({ src: $img.attr("src"), srcset: $img.attr("srcset") });
      e.preventDefault();
    });
  }

  get template() {
    return _.template(`
      <div class="expanded row product-images__controls">
        <a class="small-2 columns product-images__controls__prev">
          <span></span>
        </a>
        <div class="small-8 columns">
          <img src="" srcset="" class="product-images__controls__image" />
        </div>
        <a class="small-2 columns product-images__controls__next">
          <span></span>
        </a>
        <a href="#" class="product-images__controls__close">
          <span class="show-for-sr">Close</span>
          <span aria-hidden="true">×</span>
        </a>
      </div>`);
  }

  createControls() {
    const $controls = $(this.template());
    $("body").css({ overflow: "hidden" });
    this.isFullScrean = true;
    this.attacheControlsEvents($controls);
    this.$mainImage = $controls.find("img");
    this.$productImages.prepend($controls);
    this.$productImages.addClass("product-images--fullscrean");
    if (this.urls.length === 1) {
      $controls.find(".product-images__controls__next").hide();
      $controls.find(".product-images__controls__prev").hide();
      this.$mainImage.parent().addClass("small-12");
      this.$mainImage.parent().removeClass("small-8");
    }
  }

  removeGallery() {
    const $controls = $(".product-images__controls");
    this.$productImages.removeClass("product-images--fullscrean");
    $controls.remove();
    this.isFullScrean = false;
    this.currentUrl = null;
    $("body").css({ overflow: "auto" });
  }

  attacheControlsEvents($controls) {
    $controls.find(".product-images__controls__close").on("click.productGallery", e => {
      this.removeGallery();
      e.preventDefault();
    });
    $controls.find(".product-images__controls__next").on("click.productGallery", e => {
      this.next();
      e.preventDefault();
    });
    $controls.find(".product-images__controls__prev").on("click.productGallery", e => {
      this.previous();
      e.preventDefault();
    });

    $(document).on("keyup", e => {
      const that = this;
      Foundation.Keyboard.register("productGallery", {
        ESCAPE: "close",
        ARROW_LEFT: "prev",
        ARROW_RIGHT: "next",
      });

      Foundation.Keyboard.handleKey(e, "productGallery", {
        close: () => {
          that.removeGallery();
          e.preventDefault();
        },
        prev: () => {
          that.previous();
          e.preventDefault();
        },
        next: () => {
          that.next();
          e.preventDefault();
        },
      });
    });
  }

  attachOnclickEvents() {
    this.$productImages.find(".product-images__link img").each((index, image) => {
      this.urls.push({ src: $(image).attr("src"), srcset: $(image).attr("srcset") });
    });

    this.$productImages.find("a").on("click.productGallery", e => {
      const $img = $(e.currentTarget).find("img");
      if (!this.isFullScrean) {
        this.createControls();
      }
      this.setImage({ src: $img.attr("src"), srcset: $img.attr("srcset") });
      e.preventDefault();
    });
  }

  detachOnclickEvents() {
    this.$productImages
      .find("a")
      .off("click.productGallery")
      .on("click", e => {
        e.preventDefault();
      });
  }

  next() {
    let url;
    const index = this.urls.indexOf(this.currentUrl);
    if (index + 1 === this.urls.length) {
      url = this.urls[0];
    } else if (index + 1 === 0) {
      url = this.urls[index + 2];
    } else {
      url = this.urls[index + 1];
    }
    this.setImage(url);
  }

  previous() {
    let url;
    const index = this.urls.indexOf(this.currentUrl);
    if (index < 1) {
      url = this.urls[this.urls.length - 1];
    } else {
      url = this.urls[index - 1];
    }
    this.setImage(url);
  }

  preloadImg() {
    const index = this.urls.indexOf(this.currentUrl);
    let previousUrl;
    let nextUrl;
    if (index < 1) {
      previousUrl = this.urls[this.urls.length - 1];
    } else {
      previousUrl = this.urls[index - 1];
    }

    if (index === this.urls.length - 1) {
      nextUrl = this.urls[0];
    } else {
      nextUrl = this.urls[index + 1];
    }
    new Image().srcset = previousUrl.srcset;
    new Image().srcset = nextUrl.srcset;
  }

  setImage(url) {
    this.currentUrl = url;
    this.$mainImage.attr(this.currentUrl);
    this.$productImages.find("a").removeClass("product-images__link--current");
    this.$productImages.find(`[src$="${url.src}"]`).parents("a").addClass("product-images__link--current");
    this.preloadImg();
  }
}

class VariantGallery {
  constructor(productImages, variantForm) {
    this.$productImages = $(productImages);
    this.$variantForm = $(variantForm);
    this.currentVariantColor = $("#id_variant").data("current-color").toLowerCase();
    this.$variantSelect = this.$variantForm.find("select");
    this.$galleryElement = $(productImages).find("a");
    this.$imageSize = this.$galleryElement.find("img").attr("sizes");

    this.$variantSelect.on("selectOption", (e, option) => {
      this.selectOption(option);
    });
    this.render();
  }

  selectOption(option) {
    if (option.type && option.type === "color") {
      this.$galleryElement.each((index, element) => {
        let elementColor = $(element).data("color");
        if (elementColor !== null) {
          elementColor = elementColor.toLowerCase();
        }
        $(element).removeClass("product-images__link--first");
        if (elementColor && elementColor !== option.raw_value.toLowerCase()) {
          $(element).removeClass("show");
          $(element).addClass("hide");
        } else {
          $(element).removeClass("hide");
          $(element).addClass("show");
        }
      });
      const $firstElement = this.$galleryElement.filter(".show").first();
      $firstElement.addClass("product-images__link--first");
      $firstElement.find("img").attr("sizes", this.$imageSize);

      if (Foundation.MediaQuery.current === "small") {
        this.$productImages.slick("removeSlide", null, null, true);
        this.$galleryElement.each((index, element) => {
          if ($(element).hasClass("show")) {
            this.$productImages.slick("slickAdd", $(element));
          }
        });
        $("a.slick-slide").on("click", e => {
          e.preventDefault();
        });
      }
    }
  }

  preloadImg() {
    const $productImages = $(".product-images").find("a");
    const colorArray = [];

    $productImages.each((index, element) => {
      const imageColor = $(element).data("color");
      const colorIndex = colorArray.indexOf(imageColor);
      if (colorIndex === -1) {
        colorArray.push(imageColor);
        const url = $(element).attr("href");
        new Image().src = url;
      }
    });
  }

  render() {
    this.$galleryElement.each((index, element) => {
      let elementColor = $(element).data("color");
      if (elementColor !== null) {
        elementColor = elementColor.toLowerCase();
      }
      if (elementColor && elementColor !== this.currentVariantColor) {
        $(element).addClass("hide");
      } else {
        $(element).addClass("show");
      }
    });
    if (Foundation.MediaQuery.current === "small") {
      this.$galleryElement.each((index, element) => {
        let elementColor = $(element).data("color");
        if (elementColor !== null) {
          elementColor = elementColor.toLowerCase();
        }
        if (elementColor && elementColor !== this.currentVariantColor) {
          const slickIndex = $(element).parent().parent().data("slick-index");
          this.$productImages.slick("slickRemove", slickIndex);
        }
        $(".slick-slide").each((slideIndex, slideElement) => {
          $(slideElement).data("slick-index", slideIndex);
        });
      });
    }
    this.preloadImg();
  }
}

function lazyloadProductImgs() {
  const $productHiddenImages = $(".hidden-image");
  $productHiddenImages.each((index, element) => {
    const placeholder = $(element).attr("js-src");
    const srcset = $(element).attr("js-data-srcset");
    const src = $(element).attr("js-data-src");

    $(element).addClass("lazyload stretch-image lazypreload blur-up");
    $(element).attr({
      src: placeholder,
      "data-srcset": srcset,
      "data-src": src,
    });
    $(element).removeAttr("js-data-src js-data-srcset js-src");
    $(element).removeClass("hidden-image");
  });
}

$(document).on("sessionData", () => {
  $(document).ready(() => {
    $(document).foundation();
    lazyloadProductImgs();
    const $productImages = $("[data-product-images]");
    if ($productImages.length) {
      if (Foundation.MediaQuery.current === "small") {
        $productImages.not(".slick-initialized").slick({
          dots: true,
          arrows: false,
          infinite: false,
          touchThreshold: 7,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
        });

        $productImages.find("a").on("click.productGallery", e => {
          e.preventDefault();
        });
      } else {
        new FullScreanGallery($productImages);
      }
      const $variantForm = $("[data-variants-form]");
      const $variantPicker = $("#variants-picker");
      if ($variantPicker.length) {
        new VariantGallery($productImages, $variantForm);
      }
    }
  });
});
