import $ from "jquery";

function createCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `;expires=${date.toUTCString()}`;
  }
  // eslint-disable-next-line compat/compat
  document.cookie = `${name}=${value}${expires};domain=${window.location.hostname};path=/`;
}

function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function getCookie(name) {
  if (document.cookie.length === 0) return "";
  let start = document.cookie.indexOf(`${name}=`);
  if (start === -1) return "";
  start = start + name.length + 1;
  let end = document.cookie.indexOf(";", start);
  if (end === -1) end = document.cookie.length;
  return unescape(document.cookie.substring(start, end));
}

function deleteCookie(name) {
  // eslint-disable-next-line compat/compat
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${window.location.hostname};path=/`;
}

function getUnixDate(date) {
  let unixDate;
  if (date) {
    unixDate = new Date(date).getTime() / 1000;
    unixDate = Math.round(unixDate);
  } else {
    unixDate = new Date().getTime() / 1000;
    unixDate = Math.round(unixDate);
  }
  return unixDate;
}

function isUnixDate(date) {
  const timestamp = date * 1000;
  const time = new Date(timestamp);
  return isNaN(Date.parse(time)) ? false : true;
}

/*
 * Compare cookie value date with expire date.
 */

function isGDPRCookieExpired(name, expireDate) {
  let cookieValue = getCookie(name);
  cookieValue = parseInt(cookieValue, 10);
  if (!isUnixDate(cookieValue)) return false;
  if (cookieValue <= expireDate) return true;
  return false;
}

/*
 * Delete cookie if expire.
 */

function deleteExpiredGDPRCookie(cookies, date) {
  $.each(cookies, (index, cookieName) => {
    let cookieValue = getCookie(cookieName);
    if (!cookieValue) deleteCookie(cookieName);
    cookieValue = parseInt(cookieValue, 10);
    if (!isUnixDate(cookieValue)) deleteCookie(cookieName);
    if (isGDPRCookieExpired(cookieName, date)) deleteCookie(cookieName);
  });
}

function addTackingCheck(sessionData) {
  if (sessionData.is_authenticated) {
    if (sessionData.enable_ad_tracking) {
      return true;
    }
    return false;
  } else if (readCookie("anonymous_ad_tracking")) {
    return true;
  }
  return false;
}

function performanceTackingCheck(sessionData) {
  if (sessionData.is_authenticated) {
    if (sessionData.enable_performance_tracking) {
      return true;
    }
    return false;
  } else if (readCookie("anonymous_performance_tracking")) {
    return true;
  }
  return false;
}

/*
 * Set tracking settings to check if user accept cookie policy without managing.
 */

function checkDefaultCheckbox() {
  $(".cookie-accept").each((index, element) => {
    $(element).trigger("change");
  });
}

export { performanceTackingCheck, addTackingCheck, readCookie, createCookie };

$(document).on("sessionData", (e, data) => {
  $(document).ready(() => {
    const cookieBarName = "cookie_policy_accepted"; // for the cookie popup
    const anonymousPoliciesAccepted = "anonymous_policies_accepted"; // for setting acceptances for anonymous user when first entering the site
    const $cookieAlertContainer = $("#cookie-alert");
    const userIsLogged = data.is_authenticated;
    const userAccepted = userIsLogged ? data.tracking_acceptance_date : readCookie(anonymousPoliciesAccepted);
    const csrfToken = data.csrf_token;
    const getCurrentDate = getUnixDate();

    const performanceCheck = $("#id_performance");
    const socialCheck = $("#id_social-media");

    // delete cookies older than provided date
    // this only affects anonymous users, as logged in users' privacy settings are taken from database
    const GDPRCookies = [cookieBarName];
    deleteExpiredGDPRCookie(GDPRCookies, getUnixDate("2019-07-24"));
    const cookieExist = readCookie(cookieBarName);

    // Set current value for tracking settings
    if (userAccepted) {
      if (userIsLogged) {
        socialCheck.prop("checked", data.enable_ad_tracking);
        performanceCheck.prop("checked", data.enable_performance_tracking);
      } else {
        if (!readCookie(performanceCheck.data("anonymous-cookie-name"))) {
          performanceCheck.prop("checked", false);
        }
        if (!readCookie(socialCheck.data("anonymous-cookie-name"))) {
          socialCheck.prop("checked", false);
        }
      }
    }

    // if the cookie doesn't exist, then display the bar informatively
    if (!cookieExist) {
      $cookieAlertContainer.removeClass("hide");
    }

    $(".cookie-accept").on("change", element => {
      const cookieName = $(element.currentTarget).data("anonymous-cookie-name");
      const csrfSwitchToken = $("input[name=csrfmiddlewaretoken]").val();
      const fieldName = $(element.currentTarget).attr("name");
      const requestData = {};
      requestData[fieldName] = $(element.currentTarget).is(":checked");
      requestData["csrfmiddlewaretoken"] = csrfSwitchToken;

      if (userIsLogged) {
        $.ajax({
          url: $(element.currentTarget).attr("action"),
          method: "post",
          data: requestData,
        });
      } else if ($(element.currentTarget).is(":checked")) {
        createCookie(cookieName, getCurrentDate, 1000);
      } else {
        deleteCookie(cookieName);
      }
    });

    // accept the cookies in the background and set the acceptance cookie
    if (!userAccepted) {
      checkDefaultCheckbox();

      if (userIsLogged) {
        $.ajax({
          url: `${window.location.protocol}//${window.location.host}/profile/cookie-policy/`,
          method: "POST",
          data: {
            id: data.user_id,
            csrfmiddlewaretoken: csrfToken,
          },
        });
      } else {
        createCookie(anonymousPoliciesAccepted, getCurrentDate, 1000);
      }
    }

    $("#privacy_settings").on("click", element => {
      $cookieAlertContainer.removeClass("hide");
      element.preventDefault();
    });

    $cookieAlertContainer.find(".confirm-button").on("click", element => {
      element.preventDefault();
      $cookieAlertContainer.addClass("hide");
      createCookie(cookieBarName, getCurrentDate, 365);
    });

    $cookieAlertContainer.find(".manage-button").on("click", () => {
      $cookieAlertContainer.toggleClass("manage");
    });
  });
});
