import $ from "jquery";

const gtmSessionDataReady = () => {
  const details = { event: "session-data-ready" };
  dataLayer.push(details);
};

const isSessionDataReady = () => {
  const readyEvents = dataLayer.filter(item => {
    return item.event === "session-data-ready";
  });
  return readyEvents.length > 0;
};

$(document).on("sessionData", (e, data) => {
  $(document).ready(() => {
    dataLayer.push({ client_id: data.client_id });
    dataLayer.push({ user_id: data.user_id });
    dataLayer.push({ user_email: data.user_email });
    $(document).trigger("gtmDataReady", {});
    gtmSessionDataReady();
  });
});

const callOnGtmDataReady = callback => {
  /**
   * This function will call callback function when GTM data will be ready.
   * Especially helpfull on asynchronous calls.
   */
  if (isSessionDataReady()) {
    callback();
    return;
  }
  $(document).on("gtmDataReady", callback);
};

const gtmMeasureRemoveFromCart = e => {
  if (e) {
    const details = $(e.currentTarget).data("gtm-remove-from-cart");
    if (details) {
      const data = {
        event: "eec.remove",
        ecommerce: {
          remove: {
            actionField: {
              list: "BASKET PAGE",
            },
            products: [details],
          },
        },
      };
      dataLayer.push(data);
    }
  }
};

const gtmMeasureCheckoutDelivery = () => {
  const details = $("[data-gtm-checkout]").data("gtm-checkout");
  if (details) {
    const checkoutStep = details.ecommerce.checkout.actionField.step;
    if (checkoutStep == 2) {
      $("[data-gtm-checkout]").on("click", e => {
        const loginData = $(e.currentTarget).data("gtm-checkout");
        dataLayer.push(loginData);
      });
    } else dataLayer.push(details);
  }
};

const gtmMeasurePurchase = () => {
  const details = $("[data-gtm-purchase]").data("gtm-purchase");
  const metricsUrl = $("[data-order-metrics-url]").data("order-metrics-url");
  if (details) {
    // inform backend that data were pushed to dataLayer
    $.ajax({
      url: metricsUrl,
      method: "POST",
      headers: {
        "X-CSRFToken": $("[name=csrfmiddlewaretoken]").val(),
      },
    });
    dataLayer.push(details);
  }
};

const gtmProductImpressions = () => {
  const details = $("[data-gtm-product-recommendation-impressions]").data("gtm-product-recommendation-impressions");
  if (details) dataLayer.push(details);
};

const updateDataLayer = () => {
  gtmMeasureCheckoutDelivery();
  gtmMeasurePurchase();
  gtmMeasureRemoveFromCart();
};

$(document).ready(() => {
  callOnGtmDataReady(updateDataLayer);

  $("[data-gtm-remove-from-cart]").on("click", e => {
    gtmMeasureRemoveFromCart(e);
  });
});

export { callOnGtmDataReady, gtmProductImpressions };
